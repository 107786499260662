import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

const appsData = [
  { 
    name: "My Social Manager", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/my_social_manager/mysm.png", 
    description: `A powerful tool to manage your social media accounts. 
    Visit the website <a href="https://mysocialmanager.app/" target="_blank" rel="noopener noreferrer">here</a>. 
    Access the backend <a href="https://portal.mysocialmanager.app/" target="_blank" rel="noopener noreferrer">here</a>.`
  },
  { name: "VK Analytics", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_analytics/VK%20ANALYTICS.png", 
    description: "Analyze data with VK Analytics. Currently under development to integrate with MySocialManager App" 
  },
  { 
    name: "VK Payments", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_pay/VKPAY3.png", 
    description: 'A seamless payment platform integration using Angular/React. Visit the website <a href="https://payments.visionkraftconsulting.com/" target="_blank" rel="noopener noreferrer">here</a>.'
  },
  { name: "VK Image Generator", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_image/vkImage3.png", 
    description: 'Generate images using AI. Visit the website <a href="https://www.instagram.com/mysocialmanager.app/" target="_blank" rel="noopener noreferrer">here</a> and <a href="https://www.instagram.com/visionkraftconsulting/" target="_blank" rel="noopener noreferrer">here</a> to see sample of how we have integrated image generation.' 
  },
  { name: "VK Edu", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_edu/vkEdu1.png", 
    description: 'Education management platform. Visit the website <a href="https://edu.visionkraftconsulting.com/" target="_blank" rel="noopener noreferrer">here</a>.' 
  },
  { name: "VK Audio Processor", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_audio/vkAudio2.png", 
    description: "Process and enhance audio. Currently under development." 
  },
  { name: "VK Accent Changer", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_accent/vkAccent.png", 
    description: "Change and improve accents for call centers. Currently under development." 
  },
  { name: "Kazi Link", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/kazi_link/kazilink.png", 
    description: "A job-matching platform. Currently under development." 
  },
  { name: "VK Leads", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_leads/vkLeads2.png", 
    description: "Manage and track your leads. Currently under development." 
  },
  { name: "SGA Crypto", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/sga_crypto/sga1.png", 
    description: 'Cryptocurrency tracking platform. Visit the website Frontend <a href="https://sgacrypto.app/" target="_blank" rel="noopener noreferrer">here</a> and Telegram Frontend<a href="https://t.me/sgascannertrial/" target="_blank" rel="noopener noreferrer">here</a>.' 
  }
];

const websitesData = [
  { name: "Vision Kraft Consulting", url: "https://apps.visionkraftconsulting.com/" },
  { name: "Vision Kraft Consulting", url: "https://www.visionkraftconsulting.com/" },
  { name: "My Social Manager", url: "https://mysocialmanager.app" },
  { name: "Hearts United Homecare", url: "https://heartsunitedcare.com/" },
  { name: "Caring Hearts Medical Staffing", url: "https://chmstaffing.com/" },
  { name: "Serenity Safaris", url: "https://serenityhomessafaris.com/" },
  { name: "SGA Crypto", url: "https://sgacrypto.app/" },
  { name: "HomeCare", url: "https://www.homecare.com/" },
];

function App() {
  const [expandedApp, setExpandedApp] = useState(null);
  const [currentTab, setCurrentTab] = useState('Apps');
  const [websitesInfo, setWebsitesInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seoUrl, setSeoUrl] = useState('');
  const [seoSuggestions, setSeoSuggestions] = useState(null);
  const [lighthouseResults, setLighthouseResults] = useState(null);
  const [seoLoading, setSeoLoading] = useState(false);

  useEffect(() => {
    if (currentTab === 'Websites' && websitesInfo.length === 0) {
      fetchWebsiteData();
    }
  }, [currentTab]);

  const toggleExpand = (appName) => {
    setExpandedApp(expandedApp === appName ? null : appName);
  };

  const fetchWebsiteData = async () => {
    setLoading(true);
    try {
      const fetchedWebsites = await Promise.all(
        websitesData.map(async (website) => {
          const response = await fetch(`https://apps.visionkraftconsulting.com/scrape?url=${website.url}`);
          const contentType = response.headers.get('content-type');
          if (!contentType || !contentType.includes('application/json')) {
            throw new Error('Invalid JSON response');
          }
          const data = await response.json();
          return { ...website, ...data };
        })
      );
      setWebsitesInfo(fetchedWebsites);
    } catch (error) {
      console.error('Error fetching website data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSeoSuggestions = async () => {
    setSeoLoading(true);
    setSeoSuggestions(null);
    setLighthouseResults(null);
    try {
      const response = await fetch(`https://apps.visionkraftconsulting.com/scrape?url=${encodeURIComponent(seoUrl)}`);
      if (!response.ok) throw new Error('Failed to fetch SEO suggestions');
      const data = await response.json();
      setSeoSuggestions(data.seoSuggestions || ['No SEO suggestions available']);
      setLighthouseResults({
        performanceScore: data.lighthouse?.performanceScore * 100 || 'N/A',
        seoScore: data.lighthouse?.seoScore * 100 || 'N/A',
        audits: data.lighthouse?.audits || [],
      });
    } catch (error) {
      console.error('Error fetching SEO suggestions:', error);
      setSeoSuggestions(['Error fetching SEO suggestions. Please try again later.']);
    } finally {
      setSeoLoading(false);
    }
  };

  const renderApps = () => (
    <div className="App-showcase">
      {appsData.map((app) => (
        <div key={app.name} className="App-item">
          <img
            src={app.image}
            alt={`${app.name} logo`}
            className="App-item-image"
            onClick={() => toggleExpand(app.name)}
          />
          <h3 onClick={() => toggleExpand(app.name)}>{app.name}</h3>
          {expandedApp === app.name && (
            <div className="App-details">
              <p dangerouslySetInnerHTML={{ __html: app.description }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderWebsites = () => {
    if (loading) return <div className="loading-spinner">Loading...</div>;
    return (
      <div className="Websites-showcase">
        {websitesInfo.map((website) => (
          <div key={website.name} className="Website-item">
            <img
              src={website.screenshot || 'https://via.placeholder.com/150'}
              alt={`${website.name} screenshot`}
              className="Website-item-image"
            />
            <h3>{website.title || website.name}</h3>
            <p>{website.description || 'No description available'}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderSeoChecker = () => (
    <div className="Seo-checker">
      <h3>SEO Checker</h3>
      <form onSubmit={(e) => {
        e.preventDefault();
        fetchSeoSuggestions();
      }}>
        <input
          type="text"
          placeholder="Enter website URL"
          value={seoUrl}
          onChange={(e) => setSeoUrl(e.target.value)}
        />
        <button type="submit">Check SEO</button>
      </form>
  
      {seoLoading && <div className="loading-spinner">Loading SEO suggestions...</div>}
  
      {seoSuggestions && (
        <div className="seo-suggestions">
          <h4>Existing SEO Optimizations:</h4>
          <ul>
            {lighthouseResults && (
              <>
                <li><strong>Title:</strong> {lighthouseResults.title || 'No title available'}</li>
                <li><strong>Description:</strong> {lighthouseResults.description || 'No description available'}</li>
                <li><strong>H1 Tags:</strong> {lighthouseResults.h1Tags?.join(', ') || 'No H1 tags found'}</li>
                <li><strong>Canonical URL:</strong> {lighthouseResults.canonical || 'No canonical URL'}</li>
                <li><strong>Images without alt:</strong> {lighthouseResults.altTagsMissing} images missing alt attributes</li>
              </>
            )}
          </ul>
  
          <h4>SEO Suggestions:</h4>
          <ul>
            {seoSuggestions.map((suggestion, index) => (
              <li key={index}>{suggestion}</li>
            ))}
          </ul>
        </div>
      )}
  
      {lighthouseResults && (
        <div className="lighthouse-results">
          <h4>Lighthouse Results:</h4>
          <p><strong>Performance Score:</strong> {lighthouseResults.performanceScore}</p>
          <p><strong>SEO Score:</strong> {lighthouseResults.seoScore}</p>
          <h5>Audits:</h5>
          <ul>
            {Object.keys(lighthouseResults.audits).map((auditKey) => (
              <li key={auditKey}>
                <strong>{auditKey}:</strong> {lighthouseResults.audits[auditKey].title} - {lighthouseResults.audits[auditKey].score * 100}%
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
  return (
    <div className="App">
      <Helmet>
        <title>{currentTab} | VisionKraft Consulting</title>
        <meta name="description" content="VisionKraft Consulting offers full-stack development services. Explore apps and websites built by our team." />
        <link rel="canonical" href="https://visionkraftconsulting.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "VisionKraft Consulting",
            "url": "https://visionkraftconsulting.com",
            "logo": "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vision_kraft/logo_1.png",
            "sameAs": [
              "https://www.instagram.com/mysocialmanager.app/",
              "https://www.instagram.com/visionkraftconsulting/"
            ]
          })}
        </script>
      </Helmet>
      <header className="App-header">
        <img 
          src="https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vision_kraft/logo_1.png" 
          className="App-logo" 
          alt="Vision Kraft Consulting Logo" 
        />
        <h1>VisionKraft Consulting</h1>
        <h2>Welcome to VisionKraft Consulting</h2>
        <p>We are a full-stack development agency committed to delivering high-quality applications on time and within budget. From idea to deployment, we handle every step of the process professionally.</p>
        <p>Our expertise includes <strong>React</strong>, <strong>Angular</strong>, <strong>Node.js</strong>, <strong>Python</strong>, <strong>PHP</strong>, and seamless deployment on <strong>AWS Cloud Services</strong>.</p>
        <p><strong>Contact us today</strong> to discuss your project and receive a personalized quote!</p>
      </header>

      <div className="App-tabs">
        <button className={currentTab === 'Apps' ? 'active-tab' : ''} onClick={() => setCurrentTab('Apps')}>Apps</button>
        <button className={currentTab === 'Websites' ? 'active-tab' : ''} onClick={() => setCurrentTab('Websites')}>Websites</button>
        <button className={currentTab === 'SEO Checker' ? 'active-tab' : ''} onClick={() => setCurrentTab('SEO Checker')}>SEO Checker</button>
      </div>

      <div className="tab-content">
        {currentTab === 'Apps' && renderApps()}
        {currentTab === 'Websites' && renderWebsites()}
        {currentTab === 'SEO Checker' && renderSeoChecker()}
      </div>
    </div>
  );
}

export default App;